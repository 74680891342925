// import Note from './Note';
import TransportCargoCharacteristics from './TransportCargoCharacteristics';

export default class Parcel {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.length = data ? data.length : null;
    this.depth = data ? data.depth : '';
    this.height = data ? data.height : '';
    this.dimensionUnit = data ? data.dimensionUnit : '';
    this.weight = data ? data.weight : '';
    this.weightUnit = data ? data.weightUnit : '';
    this.barcode = data ? data.barcode : '';
    this.notes = [];
    this.transportCargoCharacteristics = new TransportCargoCharacteristics();
  }
}
