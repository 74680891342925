<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t('courierSet.name').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="barcode"
              :title="$t('courierSet.barcode')"
              @input="update"
            />
          </v-col>
          <v-col>
            <text-field
              v-model="numberOfPalletsToReturn"
              :title="$t('courierSet.numberOfPalletsToReturn')"
              rules="numeric"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      elevation="0"
      class="mt-4 pb-3"
    >
      <v-card-title class="pb-0">
        {{ $t('courierSet.cargo').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="cargoDescription"
              :title="$t('courierSet.cargoDescription')"
              @input="update"
            />
          </v-col>
          <v-col>
            <select-field
              v-model="typeCode"
              :title="$t('courierSet.typeCode')"
              :filed-items="typeCodes"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <select-field
              v-model="totalPackageQuantityUnitCode"
              :title="$t('courierSet.totalPackageQuantityUnitCode')"
              :filed-items="typeCodes"
              @input="update"
            />
          </v-col>
          <v-col>
            <select-field
              v-model="totalItemQuantityUnitCode"
              :title="$t('courierSet.totalItemQuantityUnitCode')"
              :filed-items="transportUnitCodes"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card
      elevation="0"
      class="mt-4 pb-3"
    >
      <v-card-title class="pb-0">
        {{ $t('courierSet.cargoAdditionalData').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-card-subtitle class="pt-0">
          W szczególności dot. zabezpieczenia jednostki transportowej poprzez wybranie odpowiedniej
          wartości dla atrybutu o kodzie PACKAGESECURITY. Odpowiednia wartość dla tego atrybutu
          powinna zostać podana przez koordynatora ze strony Raben.
        </v-card-subtitle>
        <div
          v-for="(ref, idx) in notes"
          :key="ref.id"
        >
          <CodeValueMap
            v-model="notes[idx]"
            :idx="idx"
            @updateReferences="updateReferences"
            @deleteReference="deleteReference"
          />
        </div>
        <v-row>
          <v-col
            cols="12"
            class="mb-5"
          >
            <v-btn
              :disabled="!readyToAdd"
              color="primary lighten-1"
              text
              block
              @click="addSelectedReference()"
            >
              Nowa usługa dodatkowa +
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import SelectField from '@/components/inputs/SelectField.vue';
import CodeValueMap from '@/views/Speditors/Raben/Forms/CodeValueMap.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    TextField,
    SelectField,
    CodeValueMap,
  },
  props: {
    confData: { type: Object, default: null },
    value: { type: Object, default: null },
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    confSet: null,
    notesNew: null,
    transportUnit: null,
    transportUnitId: null,
    tenantId: null,
    valid: null,
    length: null,
    depth: null,
    height: null,
    dimensionUnit: null,
    weight: null,
    weightUnit: null,
    barcode: null,
    notes: null,
    transportCargoCharacteristicsId: null,
    dimensionUnits: [
      {
        id: 'MTR',
        name: 'Metr',
      },
    ],
    weightUnits: [
      {
        id: 'KGM',
        name: 'Kilogram',
      },
    ],

    cargoDescription: null,
    typeCode: 'neam',
    totalPackageQuantityUnitCode: null,
    totalItemQuantityUnitCode: null,
    typeCodes: [
      {
        id: 'adam',
        name: 'Materiały niebezpieczne, temperatura otoczenia',
      },
      {
        id: 'ad05',
        name: 'Materiały niebezpieczne, temperatura kontrolowana +5°C',
      },
      {
        id: 'neam',
        name: 'Towar neutralny, temperatura otoczenia',
      },
      {
        id: 'ne05',
        name: 'Towar neutralny, temperatura kontrolowana +5°C',
      },
      {
        id: 'ne14',
        name: 'Towar neutralny, temperatura kontrolowana +14°C',
      },
      {
        id: 'ff02',
        name: 'Towary mrożone, temperatura kontrolowana -2°C',
      },
      {
        id: 'ff18',
        name: 'Towary mrożone, temperatura kontrolowana -18°C',
      },
      {
        id: 'ff23',
        name: 'Towary mrożone, temperatura kontrolowana -23°C',
      },
      {
        id: 'cham',
        name: 'Materiały chemiczne, temperatura otoczenia',
      },
      {
        id: 'ch05',
        name: 'Materiały chemiczne, temperatura kontrolowana +5°C',
      },
      {
        id: 'foam',
        name: 'Żywność, temperatura otoczenia',
      },
      {
        id: 'fo14',
        name: 'Żywność, temperatura kontrolowana +14°C',
      },
      {
        id: 'fo18',
        name: 'Żywność, temperatura kontrolowana +18°C',
      },
      {
        id: 'nAGD',
        name: 'AGD',
      },
    ],
    transportUnitCodes: [
      {
        id: 'ds',
        name: '1/2 paleta 60cm x 80cm',
      },
      {
        id: 'cp',
        name: '1/4 paleta 40cm x 60cm',
      },
      {
        id: 'bl',
        name: 'paleta blokowa 100cm x 120cm',
      },
      {
        id: 'cl',
        name: 'general colli',
      },
      {
        id: 'ct',
        name: 'karton 30cm x 40cm',
      },
      {
        id: 'cu',
        name: 'paleta CHEP 80cm x 120cm',
      },
      {
        id: 'pa',
        name: 'paleta one-way 80cm x 120cm',
      },
      {
        id: 'pm, mm',
        name: 'paleta max 120cm x 120cm',
      },
      {
        id: 'e2',
        name: 'pojemnik plastikowy 40cm x 60cm',
      },
      {
        id: 'ep',
        name: 'paleta EURRO 80cm x 120cm',
      },
      {
        id: 'cc',
        name: 'paleta 1/4 CHEP 60cm x 40cm',
      },
      {
        id: 'kr',
        name: 'skrzynia',
      },
      {
        id: 'l1',
        name: 'paleta LPR 80cm x 120cm',
      },
      {
        id: 'pc',
        name: 'paleta 1/2 CHEP 60cm x 80cm',
      },
      {
        id: 'pk',
        name: 'paczka',
      },
      {
        id: 'pm',
        name: 'paleta modularna',
      },
    ],
    numberOfPalletsToReturn: null,
  }),
  computed: {
    readyToAdd() {
      if (this.notes?.find((x) => x.code === '' || x.value === '')) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.getConfSet();
    this.tenantId = this.$route?.params?.orgId;
  },
  methods: {
    deleteReference({ idx }) {
      this.notes.splice(idx, 1);
      this.update();
    },
    updateReferences() {
      this.update();
    },
    addSelectedReference() {
      this.notes.push({
        id: uuidv4(),
        tenantId: this.tenantId,
        clientEntityState: this.notesNew ? 4 : 3,
        code: '',
        value: '',
      });
    },
    update() {
      this.transportUnit = {
        parameters: {
          parcel: {
            id: this.transportUnitId,
            tenantId: this.tenantId,
            clientEntityState: this.isNew ? 4 : 3,
            length: this.length ? parseFloat(this.length) : 0,
            depth: this.depth ? parseFloat(this.depth) : 0,
            height: this.height ? parseFloat(this.height) : 0,
            dimensionUnit: this.dimensionUnit,
            weight: this.dimensionUnit ? parseFloat(this.weight) : 0,
            weightUnit: this.weightUnit,
            barcode: this.barcode,

            notes: this.notes,
            transportCargoCharacteristics: {
              id: this.transportCargoCharacteristicsId,
              tenantId: this.tenantId,
              clientEntityState: this.isNew ? 4 : 3,

              cargoDescription: this.cargoDescription,
              typeCode: this.typeCode,
              totalPackageQuantityUnitCode: this.totalPackageQuantityUnitCode,
              totalItemQuantityUnitCode: this.totalItemQuantityUnitCode,
            },
          },
          numberOfPalletsToReturn: {
            id: this.numberOfPalletsToReturnId,
            tenantId: this.tenantId,
            clientEntityState: this.isNew ? 4 : 3,
            numberOfPalletsToReturn: this.numberOfPalletsToReturn
              ? this.numberOfPalletsToReturn
              : 0,
          },
        },
        targetPoint: this.targetPoint,
        dropoffPoint: this.dropoffPoint,
      };
      this.$emit('input', this.transportUnit);
    },
    getConfSet() {
      this.confSet = this.confData;
      this.transportUnit = this.confSet.rabenDefaultValues?.transportUnit;

      // this.tenantId = this.transportUnit?.tenantId ? this.transportUnit?.tenantId : null;

      this.transportUnitId = this.transportUnit?.id ? this.transportUnit?.id : null;
      this.length = this.transportUnit?.length ? this.transportUnit?.length : null;
      this.depth = this.transportUnit?.depth ? this.transportUnit?.depth : null;
      this.height = this.transportUnit?.height ? this.transportUnit?.height : null;
      this.dimensionUnit = this.transportUnit?.dimensionUnit
        ? this.transportUnit?.dimensionUnit
        : null;
      this.weight = this.transportUnit?.weight ? this.transportUnit?.weight : null;
      this.weightUnit = this.transportUnit?.weightUnit ? this.transportUnit?.weightUnit : null;
      this.barcode = this.transportUnit?.barcode ? this.transportUnit?.barcode : null;
      this.notes = this.transportUnit?.notes ? this.transportUnit?.notes : null;
      this.transportCargoCharacteristics = this.transportUnit?.transportCargoCharacteristics
        ? this.transportUnit?.transportCargoCharacteristics
        : null;

      this.transportCargoCharacteristics =
        this.confSet?.rabenDefaultValues?.transportUnit?.transportCargoCharacteristics;
      this.transportCargoCharacteristicsId = this.transportCargoCharacteristics?.id
        ? this.transportCargoCharacteristics?.id
        : null;
      this.cargoDescription = this.transportCargoCharacteristics?.cargoDescription
        ? this.transportCargoCharacteristics?.cargoDescription
        : null;
      this.typeCode = this.transportCargoCharacteristics?.typeCode
        ? this.transportCargoCharacteristics?.typeCode
        : null;
      this.totalPackageQuantityUnitCode = this.transportCargoCharacteristics
        ?.totalPackageQuantityUnitCode
        ? this.transportCargoCharacteristics?.totalPackageQuantityUnitCode
        : null;
      this.totalItemQuantityUnitCode = this.transportCargoCharacteristics?.totalItemQuantityUnitCode
        ? this.transportCargoCharacteristics?.totalItemQuantityUnitCode
        : null;

      this.returnOfPallets = this.confSet?.rabenDefaultValues?.returnOfPallets;
      this.numberOfPalletsToReturn = this.returnOfPallets?.numberOfPalletsToReturn
        ? this.returnOfPallets?.numberOfPalletsToReturn
        : null;
      this.numberOfPalletsToReturnId = this.returnOfPallets?.id ? this.returnOfPallets?.id : null;

      this.notesNew = this.transportUnit?.notes.length === 0 ? 4 : 3;
    },
  },
};
</script>

<style scoped lang="scss">
.v-select {
  padding: 0px 10px 0px 10px;
}
.col {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
