import Payment from './Payment';
// import Person from './Person';
import ReturnOfPallets from './ReturnOfPallets';
import Parcel from './Parcel';

export default class RabenDefaultValues {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.clientId = data ? data.clientId : '';
    // this.shipper = new Person();
    // this.receiver = new Person();
    // this.shipTo = new Person();
    // this.shipFrom = new Person();
    this.returnOfDocumentsIsUsed = data ? data.returnOfDocumentsIsUsed : false;
    this.returnOfPallets = new ReturnOfPallets();
    this.cashOnDelivery = new Payment();
    this.transportUnit = new Parcel();
    this.transportReferences = data ? data.transportReferences : [];
    this.shipmentNotesServices = data ? data.shipmentNotesServices : [];
  }
}
