<template>
  <div class="elevation-0 px-2">
    <v-row no-gutters>
      <v-col
        v-if="codes"
        cols="6"
        class="pr-0"
      >
        <v-select
          v-model="entry.code"
          autocomplete="off"
          label="Kod"
          clearable
          dense
          outlined
          persistent-placeholder
          :items="codes"
          :hint="hint"
          item-value="id"
          item-text="code"
          @change="$emit('updateReferences')"
        />
      </v-col>
      <v-col
        v-else
        cols="6"
        class="pr-0"
      >
        <v-text-field
          v-model="entry.code"
          autocomplete="off"
          label="Kod"
          dense
          outlined
          persistent-placeholder
          @change="$emit('updateReferences')"
        />
      </v-col>

      <v-col
        cols="5"
        class="ml-0"
      >
        <v-text-field
          v-model="entry.value"
          autocomplete="off"
          label="Wartość"
          dense
          outlined
          persistent-placeholder
          @change="$emit('updateReferences')"
        />
      </v-col>
      <v-col
        cols="1"
        class="pl-0"
      >
        <v-btn
          elevation="0"
          icon
          tile
          min-height="48"
          min-width="48"
          @click="deleteReference()"
        >
          <v-icon color="danger"> mdi-delete-outline </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object, default: null },
    codes: { type: Array, default: null },
    idx: { type: Number, default: -1 },
    hint: { type: String, default: null },
  },
  data: () => ({
    entries: [],
    entry: null,
  }),
  computed: {
    notDeletedEntries() {
      return this.entries.filter((x) => x.deleted !== true);
    },
  },
  created() {
    this.entry = this.value;
  },
  methods: {
    deleteReference() {
      this.$emit('deleteReference', { idx: this.idx });
    },
  },
};
</script>

<style></style>
