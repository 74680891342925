export default class ReturnOfPallets {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.cargoDescription = data ? data.cargoDescription : '';
    this.typeCode = data ? data.typeCode : '';
    this.totalPackageQuantityUnitCode = data ? data.totalPackageQuantityUnitCode : '';
    this.totalItemQuantityUnitCode = data ? data.totalItemQuantityUnitCode : '';
  }
}
