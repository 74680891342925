import RabenDefaultValues from './RabenDefaultValues';

export default class Raben {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.clientId = data ? data.clientId : '';
    this.configurationName = data ? data.configurationName : '';
    this.password = data ? data.password : '';
    this.metadata = data ? data.metadata : [];
    this.courier = data ? data.courier : '';
    this.login = data ? data.login : '';
    this.certifacteThumbprint = data ? data.certifacteThumbprint : '';
    this.urlAddress = data ? data.urlAddress : '';
    this.ediSender = data ? data.ediSender : '';
    this.ediSenderPrefix = data ? data.ediSenderPrefix : '';
    this.ediReceiver = data ? data.ediReceiver : '';
    this.fileType = data ? data.fileType : '';
    this.department = data ? data.department : '';
    this.payerIdentifier = data ? data.payerIdentifier : '';
    this.productType = data ? data.productType : '';
    this.rabenDefaultValues = new RabenDefaultValues();
  }
}
